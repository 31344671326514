
  $('.cp-header-slider .items-container').not('.slick-initialized').slick({
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4250,
    prevArrow: "<i class='icomoon icon-back'></i>",
    nextArrow: "<i class='icomoon icon-next'></i>",
    mobileFirst: false,
    slidesToShow: 1,
    slidesToScroll: 1
  });
/**
 * Beim onload und resize der Seite, Höhe der Tab-Inhalte ersetzen
 */

$(document).ready(function(){
  setHeightForContent();
});

/**
 * Window-resize verzögert ausführen, ansonsten wird die Höhe bei jedem Ziehen der Maus ausgeführt
 * Mit untenstehender Funktion wird setHeightForContent erst aufgerufen, wenn der Resize-Vorgang beendet ist.
 */

var doit;
window.onresize = function(){
  clearTimeout(doit);
  doit = setTimeout(setHeightForContent, 100);
};

/**
 * Maximale Höhe der Tab-Inhalte ermitteln und Box mit Schatten entsprechend anpassen
 * $(this)[0].scrollHeight ermittelt Höhe auch bei unsichtbaren Elementen.
 * Bei Breakpoint 600 und kleiner Höhe auf 'auto' setzen (für mobile devices)
 */

function setHeightForContent() {
  let maxHeight = 0;

  if (window.innerWidth >= 600) {

    $('ul.tab-list > li .tab-content').each(function(idx){

      const newHeight = $(this)[0].scrollHeight + 100;

      if (newHeight > maxHeight) {
        maxHeight = newHeight;
      }
    });

    $('ul.tab-list > li').css({
      'height': maxHeight
    });

  } else {
    $('ul.tab-list > li').css({
      'height': 'auto'
    });
  }

}

/**
 * Klick auf Tab-Button zeigt entsprechenden Inhalt
 */

$('.el-tab-element .tab-list .tab-title').click(function (e) {
  e.preventDefault();
  $('.el-tab-element .tab-list > li').removeClass('open');
  $(this).closest('.el-tab-element .tab-list > li').toggleClass("open");
});
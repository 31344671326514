
  $('.cp-shop-slider .items-container').not('.slick-initialized').slick({
    autoplay: false,
	  dots: false,
	  infinite: true,
    speed: 450,
    autoplaySpeed: 2500,
    prevArrow: "<i class='icomoon icon-back'></i>",
    nextArrow: "<i class='icomoon icon-next'></i>",
	  mobileFirst: false,
	  slidesToShow: 1,
	  slidesToScroll: 1
  });
$navIcon.on('click', function(event) {
    event.preventDefault();
    $('div.nav-bar').show();
    $mainNav.slideToggle($toggleSpeed);
    $navIcon.toggleClass('icon-menu icon-close');
});

$(window).on('scroll',function() {
    let $scrolled = $(this).scrollTop();
    if ($scrolled > 0) {
        $header.addClass('scroll');
    } else {
        $header.removeClass('scroll');
    }
});